import type { MaybeRef } from '@vueuse/core'
import type { GetOptionsReturnItem, Pool, Status } from '@deorderbook/sdk'

export interface GetOptionByParams {
  exerciseTimestamp?: string
  strikePrice?: string
  optionType?: '0' | '1'
}
export interface OptionWithMore extends GetOptionsReturnItem {
  status?: Status<['Expired', 'Active', 'Inactive']>
  endBlock?: string
  isCollectorApproved?: boolean
  isOTCApproved?: boolean
  date?: string
  isActive?: boolean
  isStarted?: boolean
  title?: string
  rewardPerBlock?: string
}
export interface FilterOptionsType {
  /**
   * true: exerciseTimestamp >= now, false: exerciseTimestamp < now
   * @default undefined
   * */
  isExerciseStarted?: true | false
  /**
   * true: pool.startBlock <= currentBlock, false: pool.startBlock > currentBlock
   * @default true
   * */
  isStarted?: true | false
  /**
   * @default true
   * */
  isActive?: true | false
  /** 0 is sell, 1 is buy */
  optionType?: '0' | '1'
}

export const useOptions = () => {
  const options: Ref<OptionWithMore[]> = ref([])

  const {
    sniperPools,
    loading: sniperPoolLoading,
    actionRefreshPools,
  } = toRefs(usePoolsStore())
  const {
    options: originData,
    loading: optionLoading,
    actionRefreshOptions,
  } = toRefs(useOptionsStore())

  const isLoading = computed(() => {
    return sniperPoolLoading.value || optionLoading.value
  })

  const filterOptions = reactive<FilterOptionsType>({
    isExerciseStarted: undefined,
    isStarted: true,
    isActive: true,
    optionType: undefined,
  })
  const { defaultProvider } = useChains()
  const filterFn = async (
    list: OptionWithMore[] | GetOptionsReturnItem[],
    filterOptions: FilterOptionsType,
  ) => {
    if (list.length === 0) return []
    let _list = list.map((option) => {
      return {
        ...option,
        isActive: filterOptions.isActive ?? true,
      }
    })

    if (filterOptions.optionType !== undefined) {
      _list = _list.filter(
        (option) => option.optionType === filterOptions.optionType,
      )
    }

    if (filterOptions.isExerciseStarted !== undefined) {
      _list = _list.filter((option) =>
        filterOptions.isExerciseStarted
          ? Number(option.exerciseTimestamp + '000') <= Date.now()
          : Number(option.exerciseTimestamp + '000') > Date.now(),
      )
    }

    if (filterOptions.isStarted !== undefined) {
      if (!defaultProvider.value) return [] as OptionWithMore[]
      const blockNumber = await defaultProvider.value?.getBlockNumber()
      _list = _list.filter((option) => {
        const pool = getPoolByOption(option)
        const isStarted = checkStarted(pool, blockNumber)
        return filterOptions.isStarted ? isStarted : !isStarted
      })
    }

    if (filterOptions.isActive !== undefined) {
      _list = _list
        .filter((option) => {
          const pool = sniperPools.value.find((pool) => pool.id === option.id)
          return filterOptions.isActive ? pool?.isActive : !pool?.isActive
        })
        .map((option) => {
          return { ...option, isActive: filterOptions.isActive! }
        })
    }

    return _list
  }

  watch(
    [filterOptions, originData, sniperPools],
    async () => {
      options.value = await filterFn(originData.value, filterOptions)
    },
    {
      immediate: true,
      deep: true,
    },
  )

  function getOptionBy(
    list: MaybeRef<OptionWithMore[]>,
    params: GetOptionByParams,
  ) {
    if (
      params.exerciseTimestamp !== undefined ||
      params.strikePrice !== undefined ||
      params.optionType !== undefined
    ) {
      return computed(() => {
        return toValue(list).find((x) => {
          const isSameExerciseTimestamp = params.exerciseTimestamp
            ? x.exerciseTimestamp === params.exerciseTimestamp
            : true
          const isSameStrikePrice = params.strikePrice
            ? x.strikePrice === params.strikePrice
            : true
          const isSameOptionType = params.optionType
            ? x.optionType === params.optionType
            : true
          return (
            isSameExerciseTimestamp && isSameStrikePrice && isSameOptionType
          )
        })
      })
    }
    return ref(undefined)
  }
  function getPoolByOption(option: OptionWithMore) {
    return sniperPools.value.find((pool) => pool.id === option.id)
  }

  const checkStarted = (pool: Pool | undefined, currentBlock: number) => {
    if (pool === undefined) return false
    return Number(pool.startBlock) < currentBlock
  }

  const refresh = (force = false) => {
    actionRefreshPools.value(force)
    actionRefreshOptions.value(force)
  }

  return {
    isLoading,
    options,
    filterOptions,
    getOptionBy,
    refresh,
    filterFn,
    getPoolByOption,
  }
}
